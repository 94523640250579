import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import {
  Button,
} from 'semantic-ui-react';

const StyledButton = styled(Button)`
`;

export default styled(({ className, href, ...props }) => {
  if (href) {
    return (
      <div className={cx(className, 'bl-text')}>
        <div className="block">
          <a href={href} target="_blank"  rel="noopener noreferrer">
            <StyledButton {...props}/>
          </a>
        </div>
      </div>
    );
  }
  return (
    <div className={cx(className, 'bl-text')}>
      <div className="block">
        <StyledButton {...props}/>
      </div>
    </div>
  );
})`
  :hover {
    background-color: white !important;
    color: #15919B !important;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &.h1 {
    .block {
      font-weight: bold;
      font-size: 38px;
      line-height: 44px;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 32px;
        line-height: 40px;
      }  
    }
  }
  &.h2 {
    .block {
      font-weight: bold;
      font-size: 26px;
      line-height: 30px;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 24px;
        line-height: 28px;
      }  
    }
  }
  &.h3 {
    .block {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 22px;
        line-height: 26px;
      }  
    }
  }
  .ui.button {
    padding: 1.1em 4em 1.1em !important;
    font-weight: 300 !important;
  }
  &.black {
    .ui.button {
      color: ${({ theme }) => theme.primaryWhite};
      background: ${({ theme }) => theme.primaryBlack};
    }
  }
  &.pink {
    .ui.button {
      color: ${({ theme }) => theme.primaryWhite};
      background: ${({ theme }) => theme.primaryPink};
    }
  }
  &.orange {
    .ui.button {
      color: ${({ theme }) => theme.primaryWhite};
      background: ${({ theme }) => theme.primaryOrange};
    }
  }
  &.yellow {
    .ui.button {
      color: ${({ theme }) => theme.primaryWhite};
      background: ${({ theme }) => theme.primaryYellow};
    }
  }
  &.teal {
    .ui.button {
      color: ${({ theme }) => theme.primaryWhite};
      background: ${({ theme }) => theme.primaryTeal};
    }
  }
`
