import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

export default styled(({ className, text }) => (
  <div className={cx(className, 'bl-text')}>
    <div className="block">{text}</div>
  </div>
))`
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &.h1 {
    .block {
      font-weight: bold;
      font-size: 38px;
      line-height: 44px;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 32px;
        line-height: 40px;
      }  
    }
  }
  &.h2 {
    .block {
      font-weight: bold;
      font-size: 26px;
      line-height: 30px;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 24px;
        line-height: 28px;
      }  
    }
  }
  &.h3 {
    .block {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 22px;
        line-height: 26px;
      }  
    }
  }
  &.pink {
    color: ${({ theme }) => theme.primaryPink};
  }
  &.orange {
    color: ${({ theme }) => theme.primaryOrange};
  }
  &.yellow {
    color: ${({ theme }) => theme.primaryYellow};
  }
  &.teal {
    color: ${({ theme }) => theme.primaryTeal};
  }

`;
