// eslint-disable-next-line
import React from 'react';

export default ({ children }) => {
  return (
    <>
      {children}
    </>
  )
};
