import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

export default styled(({ className, text }) => (
  <li className={cx(className, 'bl-ListItem')}>
    {text}
  </li>
))`
  padding: ${({ theme }) => `10px ${theme.p1}`};
  margin-bottom: 10px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ theme }) => `0 ${theme.ps1}`} 0 0;
    
  }
  font-size: 20px;
  line-height: 27px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 16px;
  }
  strong {
    font-weight: bold;
  }
  a {
    overflow-wrap: break-word;
  }
  overflow-wrap: break-word;
`;
