import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import cx from 'classnames';
import _ from 'lodash';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Block from './Block';

const settings = {
  arrows: false,
  dots: true,
  infinite: false,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  // variableWidth: true,
  // responsive: responsiveGen({ itemWidth: 265, maxWidth: 960 }),
  adaptiveHeight: true,
};

const ListSliderContext = React.createContext();

const ListSlider = styled(({ className, slides }) => {
  return (
    <div className={cx(className, 'bl-ListSlider')}>
      <div className="block">
        <Slider {...settings}>
          {_.castArray(slides || []).map((slide, index) => {
            const slideIndex = `slide-${index}`;
            return (
              <ListSliderContext.Provider value={{ index }} key={slideIndex}>
                <Block {...slide} />
              </ListSliderContext.Provider>
            );
          })}
        </Slider>
      </div>
    </div>
  );
})`
  .slick-list {
    background: ${({ theme }) => theme.primaryLightGrey};
    padding: ${({ theme }) => theme.p1} 0;
  }
  .slick-dots {
    background: ${({ theme }) => theme.primaryLightGrey};
    padding: ${({ theme }) => theme.p1} 0;
    margin-bottom: ${({ theme }) => theme.p1};
    position: static !important;
  }
  padding: ${({ theme }) => theme.p1} 0;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ theme }) => theme.ps1} 0;
  }
`;

ListSlider.Context = ListSliderContext;

export default ListSlider;
