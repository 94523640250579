import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import cx from 'classnames';
import Block from './Block';

const formatParam = (formatter, vars) => {
  const compiled = _.template(formatter);
  return compiled(vars);
};

export default styled(({ className, formatter }) => (
  <Block
    component="ListSlider.Context.Consumer"
    props={{
      children: ({ index }) => (
        <div className={cx(className, 'bl-ListIndex')}>
          <div className="block">
            {formatter ? formatParam(formatter, { index }) : index}
          </div>
        </div>
      ),
    }}
  />
))`
  padding: ${({ theme }) => theme.p1};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ theme }) => theme.ps1};
  }
  .block {
    font-size: 50px;
    font-weight: bold;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 30px;
    }
  }
`;
