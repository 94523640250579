/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';

// import { getOneOf } from '../../lib/utils';

export const NewsDetail = props => {
  const { cat } = props;
  return (
    <div className={cx(props.className, 'type-4')}>
      <Grid className="content">
        <Grid.Column className="header" mobile={16} tablet={16} computer={16}>
          <div className="tags">
            {_.get(cat, 'tags', []).map(tag => {
              return (<div className="tag text-small" key={tag}>{tag}</div>)
            })}
          </div>
          <div className="title text-h1">
            {_.get(cat, 'title', 'Title')}
          </div>
          <div className="date">
            {_.get(cat, 'date_scheduled', 'date_scheduled')}
          </div>
          <div className="divider" />
        </Grid.Column>
        <Grid.Column className="details" mobile={16} tablet={16} computer={16}>
          <div className="desc-container">
            <div className="desc text-p">
              <ReactMarkdown source={_.get(cat, 'full_copy', 'full_copy')} />
            </div>
            {
              !!_.get(cat, 'attachments.length') &&
              <div className="attachments">
                <h3>
                  {`${_.get(cat, 'attachments.length') > 1 ? 'Attachments' : 'Attachment'}`}
                </h3>
                {
                  _.get(cat, 'attachments').map((attachment, index) => {
                    return (
                      <div key={index}>
                        <a className="attachment" href={attachment.url} target="_blank" rel="noopener noreferrer">{_.last(_.split(attachment.url, '/'))}</a>
                      </div>
                      
                    )
                  })
                }
              </div>
            }
          </div>
        </Grid.Column>
      </Grid>
      {/* Data for tracking */}
      <div className="trackingData">
        <div className="location">{cat.Area}</div>
        <div className="category">{cat.table}</div>
      </div>
    </div>
  );
};

export default styled(NewsDetail)`
  .content {
    padding: 20px 10px;
    font-weight: normal;
    .button-more {
      color: ${props => props.theme.primaryWhite};
      background: ${props => props.theme.primaryBlack};
      border: 1px solid ${props => props.theme.primaryBlack} !important;
      border-radius: 20px !important;
      padding: 2px 20px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      display: inline-block;
    }
    .header {
      .button-more {
        @media (max-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .details {
      .button-more {
        @media (min-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .eligibility {
      @media (max-width: ${({ theme }) => theme.notDesktop}) {
        display: none;
      }  
    }
    .title {
      font-weight: 700;
      word-wrap: break-word;
    }
    .sub-title {
      font-size: 16px;
      font-weight: 500;
    }
    .tags {
      .tag {
        text-decoration: underline;
        display: inline-block;
        color: #707070;
        &:not(:first-child) {
          &:before {
            content: '|';
            margin: 0 2px;
            text-decoration: none;
          }
        }
      }
    }
    .tag-info {
      cursor: initial !important;
    }
    .desc-container {
      .label {
        font-weight: 700;
        margin-bottom: 0px;
      }
      .desc {
      }
      margin-bottom: 10px;
      h1, h2, h3, h4 {
        margin-bottom: 4px;
      }
      a {
        color: ${props => props.theme.txtTeal};
        text-decoration: underline;
      }
      .attachments {
        margin-top: 30px;
        .attachment {
          font-size: 16px;
          line-height: 28px;      
        }
      }
    }
  }
  height: 100%;
  .divider {
    border-bottom: ${props => props.theme.divider};
    width: 200px;
    margin: 30px 0 0;
  }
  .date {
    font-size: 16px;
    line-height: 28px;
  }
  &.type-1 {
    .content {
    }
    .title, .tos, .sub-title-2 {
      color: ${props => props.theme.txtOrange};
    }
  }
  &.type-2 {
    .content {
    }
    .title, .tos, .sub-title-2 {
      color: ${props => props.theme.txtTeal};
    }
  }
  &.type-3 {
    .content {
    }
    .title, .tos, .sub-title-2 {
      color: ${props => props.theme.txtYellow};
    }
  }
  &.type-4 {
    .content {
    }
    .title, .tos, .sub-title-2 {
      color: ${props => props.theme.txtPink};
    }
  }

`;
