import React from 'react';

import Block from './Block';

const BlockList = ({ blocks }) => (
  <>
    {blocks.map((block, index) => {
      const keyIndex = `key-${index}`;
      if (Array.isArray(block)) {
        return <BlockList blocks={block} key={keyIndex} />;
      }
      return <Block {...block} key={keyIndex} />;
    })}
  </>
);

export default BlockList;
