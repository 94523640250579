import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { Grid } from 'semantic-ui-react';

import Block from './Block';

const Columns = styled(({ className, columns }) => {
  return (
    <Grid className={cx(className)}>
      {columns &&
        columns.map((column, index) => {
          const key = `col-${index}`;
          const { settings, ...props } = column;
          return (
            <Grid.Column key={key} {...settings}>
              <Block {...props} />
            </Grid.Column>
          );
        })}
    </Grid>
  );
})`
  display: flex;
`;

export default Columns;
