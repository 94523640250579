import React from 'react';


const popupContentBody = `
!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/7c623c4916e3b4c6839e07a36/0c9fec5efe21b691f1806d46d.js");
`;

const PopupSubscribeForm = () => {
  return (
    <script 
      id="mcjs"
      dangerouslySetInnerHTML={{
        __html: popupContentBody,
      }}
    />
  )
};

export default PopupSubscribeForm;
