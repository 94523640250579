import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

import BlockList from './BlockList';

export default styled(({ className, blocks }) => (
  <div className={cx(className, 'bl-ListSlide')}>
    <div className="block">
      <BlockList blocks={blocks} />
    </div>
  </div>
))`
  background: ${({ theme }) => theme.primaryLightGrey};
`;
