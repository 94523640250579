import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';

import Quote from './Blocks/Quote';
import Subtitle from './Blocks/Subtitle';
import BodyText from './Blocks/BodyText';
import HDivider from './Blocks/HDivider';
import ListSlider from './Blocks/ListSlider';
import ListItem from './Blocks/ListItem';

import TextWidget from '../widgets/Text';
import ButtonWidget from '../widgets/Button';
import SliderWidget from '../widgets/Slider';

const widgets = [
  TextWidget,
  ButtonWidget,
  SliderWidget,
];

const matchWidget = (line) => {
  let rtn;
  widgets.find(widget => {
    const {
      pattern,
      fromBlock,
      toView,
    } = widget;

    if(pattern) {
      const matched = line.match(pattern);
      if(matched && _.isFunction(fromBlock)) {
        const obj = fromBlock(matched);
        if(_.isFunction(toView)) {
          rtn = toView(obj);
          return true;
        }
      }
    }
    return false;
  });
  return rtn;
};

export const HTMLContent = ({ content, className, post }) => {
  if(post) {
    const { rawMarkdownBody } = post;
    return (
      <ReactMarkdown
        className="pastMeetups-description"
        source={rawMarkdownBody}
        renderers={{
          blockquote: (props) => {
            const quote = _.get(props, 'children.0.props.children.0.props.children');
            // caption is the second child
            const caption = _.get(props, 'children.1.props.children.0.props.children');
            return <Quote text={quote} caption={caption}/>;
          },
          text: (props) => {
            const { children } = props;
            // support md line break syntax
            const lines = _.split(children, /\\\n/);
            if(lines.length >= 1) {
              return (
                <>
                  {lines.map((line, index) => {
                    const widget = matchWidget(line);
                    if (widget) {
                      return widget;
                    }
                    return (index === (lines.length - 1)) ?
                      line :
                      <React.Fragment key={index}>
                        {line}
                        <div style={{ height: '0.5rem' }}/>
                      </React.Fragment>;
                  })}
                </>
              );  
            }
            return children;
          },
          heading: (props) => {
            const text = _.get(props, 'children.0.props.children');
            const level = _.get(props, 'level');
            return <Subtitle text={text} level={level}/>;
          },
          paragraph: (props) => {
            const text = _.get(props, 'children');
            return <BodyText text={text} />;
          },
          thematicBreak: () => {
            return <HDivider />;
          },
          list: (props) => {
            const prefix = '@ListSlider#';
            // check if bestfit ListSlider
            const { children } = props;
            const isBFSlider = _.every(_.castArray(children || []), (listItem => {
              const listHeader = _.get(listItem, 'props.children.0.props.children.0.props.children');
              return (_.startsWith(_.trim(listHeader), '@ListSlider'));
            }));

            if(isBFSlider) {
              return <ListSlider slides={children.map(child => {
                const slideChildren = _.get(child, 'props.children');
                const [listTitleChild, ...restChildren] = slideChildren;
                const listTitle = _.trimStart(`${_.get(listTitleChild, 'props.children.0.props.children')}`.substring(prefix.length));
                return {
                  component: 'ListSlide',
                  props: {
                    blocks: [
                      {
                        component: 'ListIndex',
                        props: {
                          // eslint-disable-next-line
                          formatter: '${index + 1}.',
                        },
                      },
                      {
                        component: 'Subtitle',
                        props: {
                          text: listTitle,
                        },
                      },
                      ...restChildren.map((child) => {
                        const { type } = child;
                        if(type === 'img') {
                          return {
                            component: 'BodyImage',
                            props: {
                              image: _.get(child, 'props.src'),
                            },
                          }
                        }
                        return {
                          component: 'BodyText',
                          props: {
                            text: _.get(child, 'props.children'),
                          },
                        }
                      }),
                    ],
                  },
                };
              })} />
            }
            const ordered = _.get(props, 'ordered');
            return ordered ? 
              (
                <ol>
                  {props.children.map((child) => {
                    return <ListItem key={child.key} text={_.get(child, 'props.children')} />
                  })}
                </ol>
              ) :
              (
                <ul>
                  {props.children}
                </ul>
              );
          },
        }}
      />
    )
  }
  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
  );
};

const Content = ({ content, className }) => {
  return (
    <div className={className}>{content}</div>
  );
};

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
