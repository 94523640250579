import React from 'react';
import _ from 'lodash';

import Quote from './Quote';
import BodyText from './BodyText';
import BodyImage from './BodyImage';
import Subtitle from './Subtitle';
import PageHeader from './PageHeader';
import PageImage from './PageImage';
import PageContainer from './PageContainer';
import HDivider from './HDivider';
import ListSlider from './ListSlider';
import ListSlide from './ListSlide';
import ListIndex from './ListIndex';
import ArticleSlider from './ArticleSlider';
// import SocialButtons from './SocialButtons';
import Columns from './Columns';
import RawBlock from './RawBlock';

const BlockComponents = {
};

const initBlocks = _.memoize(() => {
  registerBlock('Quote', Quote);
  registerBlock('BodyText', BodyText);
  registerBlock('BodyImage', BodyImage);
  registerBlock('Subtitle', Subtitle);
  registerBlock('PageHeader', PageHeader);
  registerBlock('PageImage', PageImage);
  registerBlock('PageContainer', PageContainer);
  registerBlock('HDivider', HDivider);
  registerBlock('ListSlider', ListSlider);
  registerBlock('ListSlide', ListSlide);
  registerBlock('ListIndex', ListIndex);
  registerBlock('Columns', Columns);
  registerBlock('RawBlock', RawBlock);
  registerBlock('ArticleSlider', ArticleSlider);
  // registerBlock('SocialButtons', SocialButtons);
});

export const Block = ({ component, props }) => {
  initBlocks();
  let BlockComponent = null;
  if (_.isFunction(component)) {
    BlockComponent = component;
  }
  if (_.isString(component)) {
    if (!_.has(BlockComponents, component)) {
      console.log(`Block ${component} is not found.`);
      return null;
    }
    BlockComponent = _.get(BlockComponents, component);
  }
  if (!BlockComponent) return null;
  return <BlockComponent {...props} />;
};

export const registerBlock = (blockName, component) => {
  // show warning if blockName conflict
  if (_.has(BlockComponents, blockName)) {
    console.warn(`Block name ${blockName} has already registered.`);
  }
  _.set(BlockComponents, blockName, component);
  return component;
};

export const registerBlockIfNotExists = (blockName, component) => {
  if (!_.has(BlockComponents, blockName)) {
    _.set(BlockComponents, blockName, component);
  }
  return component;
};

export default Block;
