import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { Image } from 'semantic-ui-react';

export default styled(({ className, image }) => (
  <div className={cx(className, 'bl-PageImage')}>
    <div className="block">
      <Image src={image} className="image" alt="page image"/>
    </div>
  </div>
))`
  padding: ${({ theme }) => theme.p1};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 0 ${({ theme }) => theme.p1} 0;
  }
  .image {
    width: 100%;
    max-width: 100%;
  }
`;
